import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse, PlanPricingData } from 'src/common/interfaces';

import { GeoOverrideHeader } from './pricing.types';

export const getGeoOverrideHeaders = (): Record<GeoOverrideHeader, string> => {
  const overrides = Object.values(GeoOverrideHeader)
    .map((header) => {
      const value = sessionStorage.getItem(header);
      return value ? [header, value] : null;
    })
    .filter(Boolean) as [GeoOverrideHeader, string][];

  return Object.fromEntries(overrides) as Record<GeoOverrideHeader, string>;
};

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptionPricing: builder.query<ApiResponse<PlanPricingData[]>, boolean>({
      query: (includeAll) => ({
        url: `payments/cost?includeAll=${includeAll.toString()}`,
        method: 'GET',
        headers: getGeoOverrideHeaders(),
      }),
    }),
  }),
});

export const { useGetSubscriptionPricingQuery } = extendedApiSlice;
