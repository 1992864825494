import { Navigate } from 'react-router-dom';
import { AI_TUTOR_NAME, FeatureFlagName } from 'src/common/constants';
import { ThmRouteObject } from 'src/common/interfaces';
import { ThemeColorMode, UserRoles } from 'src/common/enums';
import { socSimRoutes } from 'src/router/sub-routes/soc-sim';
import { careersRoutes } from 'src/router/sub-routes/careers';
import { managementDashboardRoutes } from 'src/router/sub-routes/management-dashboard';
import { routeProtector } from './route-protector';
import { certificationRoutes } from './sub-routes/certification';
export const routes: ThmRouteObject[] = [{
  path: '/',
  loader: routeProtector().requireNotLoggedIn('/dashboard').requireFeatureFlag(FeatureFlagName.REACT_HOME_PAGE),
  async lazy() {
    const {
      Home
    } = await import('src/features/home/home');
    return {
      Component: Home
    };
  }
}, {
  path: '/leaderboards',
  async lazy() {
    const {
      Leaderboards
    } = await import('src/features/leaderboards');
    return {
      Component: Leaderboards
    };
  }
}, {
  path: '/admin',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  children: [{
    path: 'networks',
    loader: routeProtector().requireAdmin().requireFeatureFlag(FeatureFlagName.REACT_ADMIN_NETWORKS_PAGE),
    async lazy() {
      const {
        AdminNetworks
      } = await import('src/features/admin');
      return {
        Component: AdminNetworks
      };
    }
  }, {
    path: 'manage-users',
    loader: routeProtector().requireMod().requireFeatureFlag(FeatureFlagName.REACT_ADMIN_MANAGE_USERS_PAGE),
    async lazy() {
      const {
        AdminManageUsers
      } = await import('src/features/admin');
      return {
        Component: AdminManageUsers
      };
    }
  }, {
    path: 'cloud',
    loader: routeProtector().requireOneOfRoles([UserRoles.SDR, UserRoles.BDM, UserRoles.CONTENT_DEV, UserRoles.AZURE_ADMIN]).requireFeatureFlag(FeatureFlagName.REACT_AWS_CLOUD_TRAINING),
    async lazy() {
      const {
        CloudTraining
      } = await import('src/features/admin/cloud-training/cloud-training');
      return {
        Component: CloudTraining
      };
    },
    children: [{
      path: 'aws-users',
      loader: routeProtector().requireOneOfRoles([UserRoles.SDR, UserRoles.BDM]).requireFeatureFlag(FeatureFlagName.REACT_AWS_CLOUD_TRAINING),
      async lazy() {
        const {
          AwsCloudUsers
        } = await import('src/features/admin/cloud-training/users');
        return {
          Component: AwsCloudUsers
        };
      }
    }, {
      path: 'aws-accounts',
      loader: routeProtector().requireOneOfRoles([UserRoles.SDR, UserRoles.BDM]).requireFeatureFlag(FeatureFlagName.REACT_AWS_CLOUD_TRAINING),
      async lazy() {
        const {
          AwsCloudAccounts
        } = await import('src/features/admin/cloud-training/accounts');
        return {
          Component: AwsCloudAccounts
        };
      }
    }, {
      path: 'azure-tenant-onboarding',
      loader: routeProtector().requireOneOfRoles([UserRoles.SDR, UserRoles.BDM, UserRoles.AZURE_ADMIN]),
      async lazy() {
        const {
          AzureTenantOnboarding
        } = await import('src/features/admin/cloud-training/azure-tenant-onboarding');
        return {
          Component: AzureTenantOnboarding
        };
      }
    }, {
      path: 'azure-lab-config',
      loader: routeProtector().requireOneOfRoles([UserRoles.AZURE_ADMIN, UserRoles.CONTENT_DEV]).requireFeatureFlag(FeatureFlagName.AZURE_CLOUD_TRAINING),
      async lazy() {
        const {
          AzureLabConfig
        } = await import('src/features/admin/cloud-training/azure-lab-config');
        return {
          Component: AzureLabConfig
        };
      }
    }]
  }, {
    path: 'user-feedback',
    loader: routeProtector().requireSDROrAnalyst().requireFeatureFlag(FeatureFlagName.REACT_ADMIN_USER_FEEDBACK),
    async lazy() {
      const {
        UserFeedback
      } = await import('src/features/user-feedback');
      return {
        Component: UserFeedback
      };
    }
  }, {
    path: 'submissions',
    loader: routeProtector().requireTester().requireFeatureFlag(FeatureFlagName.REACT_ADMIN_SUBMISSIONS_PAGE),
    async lazy() {
      const {
        Submissions
      } = await import('src/features/admin');
      return {
        Component: Submissions
      };
    }
  }, {
    path: 'corp',
    loader: routeProtector().requireBDM().requireFeatureFlag(FeatureFlagName.REACT_ADMIN_CORPORATE_PAGE),
    async lazy() {
      const {
        AdminCorporate
      } = await import('src/features/admin');
      return {
        Component: AdminCorporate
      };
    }
  }, {
    path: 'user-occupation-details',
    loader: routeProtector().requireSDR().requireFeatureFlag(FeatureFlagName.REACT_USER_OCCUPATION_PAGE),
    async lazy() {
      const {
        AdminUserOccupation
      } = await import('src/features/admin');
      return {
        Component: AdminUserOccupation
      };
    }
  }, {
    path: 'tagging',
    loader: routeProtector().requireTagAdmin().requireFeatureFlag(FeatureFlagName.TAGGING_V2_ENABLED),
    async lazy() {
      const {
        Tagging
      } = await import('src/features/admin');
      return {
        Component: Tagging
      };
    }
  }]
}, {
  path: '/manage-account',
  loader: routeProtector().requireLogin().requireFeatureFlag(FeatureFlagName.REACT_MANAGE_ACCOUNT_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      ManageAccount
    } = await import('src/features/manage-account');
    return {
      Component: ManageAccount
    };
  },
  children: [{
    path: 'account-details',
    async lazy() {
      const {
        AccountDetails
      } = await import('src/features/manage-account');
      return {
        Component: AccountDetails
      };
    }
  }, {
    path: 'password-security',
    loader: routeProtector().requireLocalUser(),
    async lazy() {
      const {
        PasswordAndSecurity
      } = await import('src/features/manage-account');
      return {
        Component: PasswordAndSecurity
      };
    }
  }, {
    path: 'subscription',
    async lazy() {
      const {
        Subscription
      } = await import('src/features/manage-account');
      return {
        Component: Subscription
      };
    }
  }, {
    path: 'teams',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.CTF_FEATURES),
    async lazy() {
      const {
        Teams
      } = await import('src/features/manage-account');
      return {
        Component: Teams
      };
    }
  }, {
    index: true,
    element: <Navigate to="account-details" replace />
  }]
}, {
  path: '/legal',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_LEGAL_PAGE),
  children: [{
    path: '',
    element: <Navigate to="/legal/terms-of-use" replace />
  }, {
    path: 'cookie-policy',
    async lazy() {
      const {
        CookiePolicy
      } = await import('src/features/legal');
      return {
        Component: CookiePolicy
      };
    }
  }, {
    path: 'privacy-policy',
    async lazy() {
      const {
        PrivacyPolicy
      } = await import('src/features/legal');
      return {
        Component: PrivacyPolicy
      };
    }
  }, {
    path: 'acceptable-use-policy',
    async lazy() {
      const {
        AcceptableUsePolicy
      } = await import('src/features/legal');
      return {
        Component: AcceptableUsePolicy
      };
    }
  }, {
    path: 'terms-of-use',
    async lazy() {
      const {
        TermsOfUse
      } = await import('src/features/legal');
      return {
        Component: TermsOfUse
      };
    }
  }, {
    path: 'business-terms-of-use',
    async lazy() {
      const {
        BusinessTermsOfUse
      } = await import('src/features/legal');
      return {
        Component: BusinessTermsOfUse
      };
    }
  }, {
    path: 'data-processing-addendum',
    async lazy() {
      const {
        DataProcessingAddendum
      } = await import('src/features/legal');
      return {
        Component: DataProcessingAddendum
      };
    }
  }, {
    path: 'ai-terms-of-use',
    async lazy() {
      const {
        AITermsOfUse
      } = await import('src/features/legal');
      return {
        Component: AITermsOfUse
      };
    }
  }]
}, ...careersRoutes, ...certificationRoutes, {
  path: '/badges',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_BADGES_PAGE).requireLogin().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Badges
    } = await import('src/features/badges');
    return {
      Component: Badges
    };
  }
}, {
  path: '/access',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_ACCESS_PAGE).requireLogin().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Access
    } = await import('src/features/access');
    return {
      Component: Access
    };
  }
}, {
  path: '/pricing',
  loader: routeProtector().requireNotLoggedIn('/why-subscribe').requireFeatureFlag(FeatureFlagName.REACT_PRICING_PAGE),
  async lazy() {
    const {
      Pricing
    } = await import('src/features/pricing');
    return {
      Component: Pricing
    };
  }
}, {
  path: '/why-subscribe',
  loader: routeProtector().requireLogin('/pricing').requireFeatureFlag(FeatureFlagName.REACT_WHY_SUBSCRIBE_PAGE),
  async lazy() {
    const {
      WhySubscribe
    } = await import('src/features/why-subscribe');
    return {
      Component: WhySubscribe
    };
  }
}, {
  path: '/not-found',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_404_PAGE),
  async lazy() {
    const {
      NotFound404
    } = await import('src/features/error-pages');
    return {
      Component: NotFound404
    };
  }
}, {
  path: '/forgot',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_FORGOT_CODE_PAGE),
  async lazy() {
    const {
      ForgotPassword
    } = await import('src/features/forgot-password');
    return {
      Component: ForgotPassword
    };
  }
}, {
  path: '/reset/:code',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_FORGOT_CODE_PAGE),
  async lazy() {
    const {
      ResetPassword
    } = await import('src/features/reset-password');
    return {
      Component: ResetPassword
    };
  }
}, {
  path: '/500',
  async lazy() {
    const {
      Error500
    } = await import('src/features/error-pages');
    return {
      Component: Error500
    };
  }
}, {
  path: '/banned',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_BANNED_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Banned
    } = await import('src/features/error-pages');
    return {
      Component: Banned
    };
  }
}, {
  path: '/about',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_ABOUT_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      AboutUs
    } = await import('src/features/about-us');
    return {
      Component: AboutUs
    };
  }
}, {
  path: '/voucher/stats/:groupId',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_VOUCHER_STATS_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Voucher
    } = await import('src/features/voucher');
    return {
      Component: Voucher
    };
  }
}, {
  path: '/contact',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_CONTACT_US_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      ContactUs
    } = await import('src/features/contact-us');
    return {
      Component: ContactUs
    };
  }
}, {
  path: '/resources/',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_RESOURCES_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Resources
    } = await import('src/features/resources');
    return {
      Component: Resources
    };
  },
  children: [{
    path: ':category',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_RESOURCES_PAGE),
    async lazy() {
      const {
        Resources
      } = await import('src/features/resources');
      return {
        Component: Resources
      };
    }
  }]
}, {
  path: 'resources/blog/:slug',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_RESOURCES_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Article
    } = await import('src/features/resources');
    return {
      Component: Article
    };
  }
}, ...socSimRoutes, ...managementDashboardRoutes, {
  path: '/glossary',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_GLOSSARY_PAGE),
  async lazy() {
    const {
      Glossary
    } = await import('src/features/glossary/glossary');
    return {
      Component: Glossary
    };
  }
}, {
  path: '/room/:roomCode',
  loader: routeProtector().requireRoomsRebuildAccess().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Room
    } = await import('src/features/room');
    return {
      Component: Room
    };
  },
  handle: {
    isHeaderSticky: false
  }
}, {
  path: '/jr/:roomCode',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.ROOMS_REBUILD_JOIN_ROOM).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      JoinRoom
    } = await import('src/features/join-room');
    return {
      Component: JoinRoom
    };
  }
}, {
  path: '/rooms',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_MY_ROOMS_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      MyRooms
    } = await import('src/features/my-rooms');
    return {
      Component: MyRooms
    };
  }
}, {
  path: '/room/manage/:roomCode',
  loader: routeProtector().requireLogin().requireFeatureFlag(FeatureFlagName.REACT_MANAGE_SINGLE_ROOM).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      ManageRoom
    } = await import('src/features/manage-room');
    return {
      Component: ManageRoom
    };
  }
}, {
  path: '/payment/pending',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_PAYMENT_PENDING_PAGE),
  async lazy() {
    const {
      PaymentPending
    } = await import('src/features/payment-pending');
    return {
      Component: PaymentPending
    };
  }
}, {
  path: '/feedback/subscription',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_SUBSCRIPTION_FEEDBACK_PAGE),
  async lazy() {
    const {
      SubscriptionFeedback
    } = await import('src/features/subscription-feedback/subscription-feedback');
    return {
      Component: SubscriptionFeedback
    };
  }
}, {
  path: '/christmas-2023',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_CHRISTMAS_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      ChristmasPage2023
    } = await import('src/features/christmas-page-2023');
    return {
      Component: ChristmasPage2023
    };
  },
  handle: {
    forcedTheme: ThemeColorMode.DARK
  }
}, {
  path: '/christmas',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_CHRISTMAS_PAGE_2024),
  async lazy() {
    const {
      ChristmasPage2024
    } = await import('src/features/christmas-page-2024');
    return {
      Component: ChristmasPage2024
    };
  },
  handle: {
    forcedTheme: ThemeColorMode.DARK,
    isHeaderTransparent: true,
    isHeaderSticky: false
  }
}, {
  path: '/attacking-and-defending-aws',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_AWS_PRICING_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      AwsPricingPage
    } = await import('src/features/aws-pricing-page');
    return {
      Component: AwsPricingPage
    };
  },
  handle: {
    forcedTheme: ThemeColorMode.LIGHT
  }
}, {
  path: '/hacktivities/',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Hacktivities
    } = await import('src/features/hacktivities');
    return {
      Component: Hacktivities
    };
  },
  children: [{
    path: '',
    async lazy() {
      const {
        HacktivitiesMainTabWrapper
      } = await import('src/features/hacktivities');
      return {
        Component: HacktivitiesMainTabWrapper
      };
    }
  }, {
    path: 'learn',
    async lazy() {
      const {
        Learn
      } = await import('src/features/hacktivities');
      return {
        Component: Learn
      };
    }
  }, {
    path: 'practice',
    async lazy() {
      const {
        Practice
      } = await import('src/features/hacktivities');
      return {
        Component: Practice
      };
    }
  }, {
    path: 'challenges',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_NEW_PRACTICE_PAGE),
    async lazy() {
      const {
        Challenges
      } = await import('src/features/hacktivities');
      return {
        Component: Challenges
      };
    }
  }, {
    path: 'search',
    async lazy() {
      const {
        Search
      } = await import('src/features/hacktivities');
      return {
        Component: Search
      };
    }
  }]
}, {
  path: 'onboarding',
  loader: routeProtector().requireLogin().requireFeatureFlag(FeatureFlagName.REACT_ONBOARDING_PAGE),
  async lazy() {
    const {
      OnboardingRenderer
    } = await import('src/features/onboarding-segments-2024');
    return {
      Component: OnboardingRenderer
    };
  },
  handle: {
    forcedTheme: ThemeColorMode.DARK,
    hideFooter: true,
    hideHeader: true
  }
}, {
  path: '/assignments',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_USER_ASSIGNMENTS_PAGE).requireBusinessUserOrCorporatePartner().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      UserAssignments
    } = await import('src/features/hacktivities');
    return {
      Component: UserAssignments
    };
  }
}, {
  path: '/p/:username',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_PUBLIC_PROFILE_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      PublicProfile
    } = await import('src/features/public-profile/public-profile');
    return {
      Component: PublicProfile
    };
  }
}, {
  path: '/:username/badges/:badgeName',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_PERSONAL_BADGE_PAGE),
  async lazy() {
    const {
      PersonalBadges
    } = await import('src/features/personal-badges');
    return {
      Component: PersonalBadges
    };
  },
  handle: {
    hideHeader: true,
    hideFooter: true
  }
}, {
  path: '/mobile-signup',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_MOBILE_SIGNUP_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      MobileSignup
    } = await import('src/features/mobile-signup');
    return {
      Component: MobileSignup
    };
  },
  handle: {
    hideFooter: true
  }
}, {
  path: '/signup',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_SIGNUP_PAGE),
  async lazy() {
    const {
      Signup
    } = await import('src/features/signup');
    return {
      Component: Signup
    };
  }
}, {
  path: '/welcome',
  loader: routeProtector().requireLogin(),
  async lazy() {
    const {
      Welcome
    } = await import('src/features/welcome');
    return {
      Component: Welcome
    };
  },
  handle: {
    hideFooter: true,
    forcedTheme: ThemeColorMode.DARK,
    hideHeader: true
  }
}, {
  path: '/dashboard',
  loader: routeProtector().requireLogin().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Dashboard
    } = await import('src/features/dashboard');
    return {
      Component: Dashboard
    };
  }
}, {
  path: '/login',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_LOGIN),
  async lazy() {
    const {
      LoginPage
    } = await import('src/features/login');
    return {
      Component: LoginPage
    };
  },
  handle: {
    hideFooter: true
  }
}, {
  path: '/login/two-factor',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_LOGIN),
  async lazy() {
    const {
      TwoFactorPage
    } = await import('src/features/login');
    return {
      Component: TwoFactorPage
    };
  },
  handle: {
    hideFooter: true
  }
}, {
  path: '/login/sso',
  loader: routeProtector().requireNotLoggedIn('/dashboard').requireFeatureFlag(FeatureFlagName.SINGLE_SIGN_ON),
  async lazy() {
    const {
      SingleSignOnLogin
    } = await import('src/features/login');
    return {
      Component: SingleSignOnLogin
    };
  }
}, {
  path: '/path',
  children: [{
    path: 'outline/:path',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_PATH_OUTLINE_PAGE).requireCompletedOnboardingAndWelcome(),
    async lazy() {
      const {
        PathOutline
      } = await import('src/features/path-outline/path-outline');
      return {
        Component: PathOutline
      };
    },
    handle: {
      isHeaderTransparent: true,
      isHeaderSticky: false
    }
  }]
}, {
  path: '/paths',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_PATHS_PAGE),
  async lazy() {
    const {
      Paths
    } = await import('src/features/paths');
    return {
      Component: Paths
    };
  }
}, {
  path: `/${AI_TUTOR_NAME.toLowerCase()}`,
  async lazy() {
    const {
      TutorLandingPage
    } = await import('src/features/landing');
    return {
      Component: TutorLandingPage
    };
  },
  handle: {
    isHeaderTransparent: true,
    isHeaderSticky: false
  }
}, {
  path: '/subscriptions',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_SUBSCRIPTIONS_PAGE),
  async lazy() {
    const {
      Subscriptions
    } = await import('src/features/subscriptions');
    return {
      Component: Subscriptions
    };
  }
}, {
  path: '/your-material',
  loader: routeProtector().requireLogin().requireFeatureFlag(FeatureFlagName.REACT_YOUR_MATERIAL_PAGE),
  async lazy() {
    const {
      YourMaterial
    } = await import('src/features/your-material');
    return {
      Component: YourMaterial
    };
  }
}, {
  path: '/upload',
  loader: routeProtector().requireLogin().requireFeatureFlag(FeatureFlagName.REACT_UPLOAD_PAGE),
  async lazy() {
    const {
      Upload
    } = await import('src/features/upload');
    return {
      Component: Upload
    };
  }
}, {
  path: '/certificate/:certificateID',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.SHARING_CERTIFICATE),
  async lazy() {
    const {
      CertificatePage
    } = await import('src/features/certificate-page');
    return {
      Component: CertificatePage
    };
  }
}];